import { requestWrapper } from '@vjs/helpers';
import httpCodes from '@vjs/config/httpCodes';
import VFormInform from '@vjs/components/VFormInform';
import FormPhoto from '@vjs/components/FormPhoto';
import {
  QForm,
  QInput,
  QBtn,
  QSelect,
  QField,
  QRadio,
  QCheckbox,
} from '@quasar/components';
import Validation from '@vjs/mixins/Validation';
import _ from 'lodash';
import VUserProfileModal from './VUserProfileModal.vue';

export default {
  name: 'VUserProfileUpdate',

  mixins: [Validation],

  components: {
    VUserProfileModal,
    VFormInform,
    QForm,
    QInput,
    QBtn,
    QSelect,
    QField,
    QRadio,
    FormPhoto,
    QCheckbox,
  },

  props: {
    createChild: {
      type: Boolean,
      default: false,
    },
    showWithoutIin: {
      type: Boolean,
      default: false,
    },
    fieldsData: {
      type: Object,
      default: () => ({}),
    },
    backUrl: {
      type: String,
      default: '',
    },
    urls: {
      type: Object,
      default: () => ({
        formAction: '',
        avatarAction: '',
        nationalities: '',
        infobyiin: '',
        confirmIin: '',
        modalAction: '',
        getProfile: '',
      }),
    },
    title: {
      type: String,
      default: '',
    },
    modalMessage: {
      type: String,
      default: null,
    },
    disabledFields: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      showModal: false,
      access: false,
      profileExists: false,
      // Если в профиле не заполнен телефон, то даём возможность заполнить
      phoneExists: false,
      loading: false,
      existsStatementMessage: null,
      childIsNonResident: false,
      childIsNonResidentMessage: null,
      form: {
        userId: '',
        iin: '',
        documentNumber: '',
        lastName: '',
        firstname: '',
        middlename: '',
        phone: '',
        additionalPhone: '',
        email: '',
        birthday: '',
        address: '',
        nationalityCurrent: { code: null },
        citizenshipCurrent: { code: null },
        genderCurrent: null,
        langCurrent: 'kz',
        avatar: '',
        nonresidentDocument: null,
        childAvatar: '',
      },
      nationalityOptions: [],
      nationalityOptionsFiltered: [],
      genderOptions: [
        {
          value: 'm',
          label: 'label.male',
        },
        {
          value: 'w',
          label: 'label.female',
        },
      ],
      langOptions: [
        {
          value: 'kz',
          label: 'label.lang.kz',
        },
        {
          value: 'ru',
          label: 'label.lang.ru',
        },
      ],
      validation: {},
      withoutIin: false,
      disableNationality: false,
      disableGender: false,
    };
  },

  beforeCreate() {
    this.$trans.add([
      'field',
      'label',
      'warning_text',
      'validation',
      'button',
      'fields',
      'placeholder',
    ]);
  },

  watch: {
    'form.email': function (val) {
      this.form.email = val.trim();
    },
  },

  async mounted() {
    this.loading = true;

    await this.getProfile();
    const natRes = await requestWrapper.call(this, {
      url: this.urls.nationalities,
    });

    if (!natRes.error) {
      this.nationalityOptions = this.nationalityOptionsFiltered = natRes.data;
    }
    this.loading = false;
  },

  methods: {
    async getProfile() {
      const profileRes = await requestWrapper.call(this, {
        url: this.urls.getProfile,
      });
      if (!profileRes.error) {
        if (profileRes.iin) {
          this.profileExists = true;
          this.iinAfterRequest = profileRes.iin;
          this.access = true;
        }
        this.phoneExists = !!profileRes.phone;
        this.form = Object.assign(this.form, profileRes);
      }
    },

    back() {
      window.history.back();
    },

    filterNationality(val, update) {
      const { nationalityOptions } = this;

      if (val === '') {
        update(() => {
          this.nationalityOptionsFiltered = nationalityOptions;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.nationalityOptionsFiltered = nationalityOptions.filter(v => v.label.toLowerCase().indexOf(needle) > -1);
      });
    },
    resetFields() {
      this.form.firstname = '';
      this.form.lastname = '';
      this.form.middlename = '';
      this.form.birthday = '';
    },
    async requestForCheckIin() {
      this.loading = true;
      const iinRes = await requestWrapper.call(this, {
        method: 'post',
        url: this.urls.infobyiin,
        data: {
          iin: this.form.iin,
        },
      }, true);

      if (iinRes.error) {
        if (iinRes.errorType === httpCodes.HTTP_I_AM_A_TEAPOT) {
          this.form.userId = iinRes.data.user_id;
          this.showModal = true;
          this.loading = false;
          return;
        }
        this.$notify({
          type: 'error',
          text: iinRes.data.message,
        });
        this.resetFields();
        this.loading = false;
        return;
      }

      this.access = true;
      this.iinAfterRequest = this.form.iin;
      this.form.firstname = iinRes.data.firstname;
      this.form.middlename = iinRes.data.lastname;
      this.form.lastname = iinRes.data.surname;
      this.form.birthday = iinRes.data.born_date;
      this.form.genderCurrent = iinRes.data.sex;
      this.loading = false;
      this.existsStatementMessage = iinRes.data.exists_statement_message;
      this.childIsNonResidentMessage = iinRes.data.child_is_non_resident_message;
      this.childIsNonResident = iinRes.data.child_is_non_resident;
      this.form.nationalityCurrent = iinRes.data.nationality;
      this.form.citizenshipCurrent = iinRes.data.citizenship;

      this.disableNationality = ![null, 143].includes(this.form.nationalityCurrent.code);
      this.disableGender = !!this.form.genderCurrent;
    },
    handleWithoutIinCheck() {
      this.form.documentNumber = '';
      this.form.iin = '';
    },
    async requestForm() {
      this.loading = true;
      const formData = new FormData();

      formData.append('iin', this.form.iin);
      formData.append('phone', this.form.phone);
      formData.append('surname', this.form.lastname);
      formData.append('firstname', this.form.firstname);
      formData.append('lastname', this.form.middlename);
      formData.append('born_date', this.form.birthday);
      formData.append('sex', this.form.genderCurrent);
      formData.append('email', this.form.email);
      formData.append('additional_phone', this.form.additionalPhone);
      formData.append('address', this.form.address);
      formData.append('lang', this.form.langCurrent);
      formData.append('nationality_id', this.form.nationalityCurrent.code);
      formData.append('citizenship_id', this.form.citizenshipCurrent.code);
      formData.append('back_url', this.backUrl);
      formData.append('document_number', this.form.documentNumber);
      formData.append('nonresidentDocument', this.form.nonresidentDocument);

      if (this.createChild) {
        formData.append('avatar', this.form.childAvatar);
      }
      const sendFormRes = await requestWrapper.call(this, {
        method: 'post',
        url: this.urls.formAction,
        data: formData,
      }, true);

      if (sendFormRes.error) {
        if (sendFormRes.errorType === httpCodes.HTTP_I_AM_A_TEAPOT) {
          this.form.userId = sendFormRes.data.user_id;
          this.showModal = true;
          this.loading = false;
          return;
        }
        this.loading = false;
        if ([422, 423].includes(sendFormRes.errorType)) {
          _.forEach(sendFormRes.data.errors, (value) => {
            this.$notify({
              type: 'error',
              text: value,
            });
          });
        }
        if (sendFormRes.errorType === httpCodes.HTTP_NOT_FOUND && sendFormRes.data.message) {
          this.$notify({
            type: 'error',
            text: sendFormRes.data.message,
          });
        }
      }

      if (sendFormRes.data.redirect) {
        window.location.href = sendFormRes.data.redirect;
      }
    },
    clearFile() {
      this.form.nonresidentDocument = null;
    },
    fileChange() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        this.form.nonresidentDocument = file;
      }
      this.$refs.fileInput.value = null;
    },
    triggerFile() {
      this.$refs.fileInput.click();
    },
  },

  computed: {
    hasValidationFail() {
      const { validation } = this;
      return field => Array.isArray(validation[field]) && validation[field].length > 0;
    },
    checkIinDisable() {
      return !this.validateIin(this.form.iin);
    },
    validate() {
      if (this.createChild) {
        if (this.createChild && this.withoutIin) {
          return this.form.genderCurrent !== null
              && this.form.documentNumber !== null
              && this.form.nonresidentDocument !== null
              && this.form.born_date !== null
              && this.form.nationalityCurrent.code !== null
            && this.form.firstname !== ''
            && this.form.lastname !== '';
        }
        return this.form.genderCurrent !== null;
      }
      return this.form.genderCurrent !== null
          && this.form.phone !== ''
          && this.form.address !== ''
          && this.validateIin(this.form.iin)
          && this.form.firstname !== ''
          && this.form.lastname !== '';
    },
  },
};
