/* eslint import/extensions: 0 */
import VVoucherFundingKindergartenGroupListFinal from './VVoucherFundingKindergartenGroupListFinal';
import VVoucherFundingCalculationView from './VVoucherFundingCalculationView.vue';
import VVoucherFundingKindergartenList from './VVoucherFundingKindergartenList';
import VVoucherFundingKindergartenListFinal from './VVoucherFundingKindergartenListFinal';
import VVoucherFundingKindergartenGroupList from './VVoucherFundingKindergartenGroupList';
import VVoucherFundingCalculation from './VVoucherFundingCalculation';
import VVoucherFundingKindergartenSpecialList from './VVoucherFundingKindergartenSpecialList';
import VVoucherFundingKindergartenListSpecialFinal from './VVoucherFundingKindergartenListSpecialFinal';
import VVoucherFundingKindergartenGroupContingentList from './VVoucherFundingKindergartenGroupContingentList';
import VVoucherFundingKindergartenSpecialListHead from './VVoucherFundingKindergartenSpecialListHead';
import VVoucherFundingKindergartenListSpecialFinalHead from './VVoucherFundingKindergartenListSpecialFinalHead';

import VVoucherApplicationInfoForm from './Application/InfoForm';
import VVoucherApplicationForm from './Application/Form';
import VVoucherApplicationShow from './Application/Show';
import VVoucherFaq from './VVoucherFaq';
import VVoucherFaqAlt from './VVoucherFaqAlt';
import VVoucherContractFaq from './VVoucherContractFaq';

import VVoucherQueue from './VVoucherQueue';
import VVoucherAbout from './VVoucherAbout';
import VVoucherTimesheetMonitoring from './VVoucherTimesheetMonitoring';

export default {
  VVoucherFundingKindergartenList,
  VVoucherFundingKindergartenGroupList,
  VVoucherFundingCalculation,
  VVoucherFundingCalculationView,
  VVoucherFundingKindergartenListFinal,
  VVoucherFundingKindergartenGroupListFinal,
  VVoucherFundingKindergartenSpecialList,
  VVoucherFundingKindergartenListSpecialFinal,
  VVoucherFundingKindergartenGroupContingentList,
  VVoucherFundingKindergartenSpecialListHead,
  VVoucherFundingKindergartenListSpecialFinalHead,

  VVoucherApplicationForm,
  VVoucherApplicationInfoForm,
  VVoucherApplicationShow,
  VVoucherFaq,
  VVoucherFaqAlt,
  VVoucherContractFaq,

  VVoucherQueue,
  VVoucherAbout,
  VVoucherTimesheetMonitoring,
};
