import {
  QInput,
  QBtn,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import VFormInform from '@vjs/components/VFormInform';
import VConfirmDialog from '@vjs/modals/VConfirmDialog.vue';
import VStatementContractRevisionModal from './VStatementContractRevisionModal.vue';

export default {
  name: 'VStatementContractShow',
  components: {
    QInput,
    QBtn,
    VStatementContractRevisionModal,
    VFormInform,
    VConfirmDialog,
  },
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        editUrl: null,
        signApiUrl: null,
        sendApiUrl: null,
        submitUrl: null,
        sendNedbUrl: null,
        downloadApiUrl: null,
        getData: null,
        staticUrl: null,
        sendRevision: null,
        editFilesUrl: null,
        deleteContract: null,
        extendApiUrl: null,
        refuseContractUrl: null,
      }),
    },
    additionalConfig: {
      type: Object,
      default: {
        statementExpiredDate: null,
        editTriesLeft: null,
      },
    },
    refuseConfig: {
      type: Object,
      required: false,
    },
    docsTypes: {
      type: [Array, Object],
      default: () => ([]),
    },
    contractName: String,
    newDateEnd: String,
    sendLabel: String,
    forCorrection: Boolean,
    forNedb: Boolean,
    sendModalConfig: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      table: [],
      revisionModal: false,
      isConfirmModalOpen: false,
      notFilled: false,
    };
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'statement',
      'statement_contract',
      'warning_text',
    ]);
    await this.requestGetData();
  },

  methods: {
    async requestDeleteContract() {
      this.handleLoading();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.apiConfig.deleteContract,
      });

      if (res.error) {
        this.handleLoading(false);
      }
    },
    async requestRefuseContractByParent() {
      this.handleLoading();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.apiConfig.refuseContractUrl,
      });

      if (res.error) {
        this.handleLoading(false);
      }
    },
    handleLoading(value = true) {
      if (value) {
        this.$globalLoading.show();
      } else {
        this.$globalLoading.hide();
      }
    },
    async requestGetData() {
      this.handleLoading();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.getData,
      });

      if (!res.error) {
        this.table = res.data.data;
        this.notFilled = res.data.notFilled;
        if (this.notFilled) {
          this.$notify({
            text: this.trans('notice.contract_info_not_filled'),
            type: 'warn',
          });
        }
      }
      this.handleLoading(false);
    },
    async requestAction() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.submitUrl,
        method: 'post',
        data: { ...this.form, language: this.language },
      });

      if (res.error) {
        this.$globalLoading.hide();
      }
    },
    async handleSendBtn() {
      if (this.sendModalConfig) {
        this.handleModal(this.sendModalConfig);

        return;
      }
      if (this.apiConfig.sendNedbUrl && !this.apiConfig.submitUrl && !this.apiConfig.signApiUrl && !this.apiConfig.extendApiUrl) {
        await this.sign();
      }
      if (this.apiConfig.submitUrl && !this.apiConfig.signApiUrl) {
        await this.requestAction();
      }
      if (this.apiConfig.sendApiUrl && !this.apiConfig.signApiUrl && !this.apiConfig.extendApiUrl && !this.apiConfig.submitUrl
        && !this.apiConfig.sendNedbUrl) {
        await this.send();
      }
      if ((this.apiConfig.signApiUrl || this.apiConfig.extendApiUrl) && !this.apiConfig.submitUrl && !this.apiConfig.sendNedbUrl) {
        await this.sign();
      }
    },
    handleModal(modalConfig) {
      this.$sModal.open(modalConfig.id, modalConfig);
    },
    async send() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.sendApiUrl,
        method: 'post',
      });

      if (res.error) {
        this.$globalLoading.hide();
      }
    },
    async sign() {
      this.handleLoading();
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = '<status>Sign</status>';

      let signUrl = this.apiConfig.signApiUrl ?? this.apiConfig.extendApiUrl;

      if (this.apiConfig.sendNedbUrl) {
        signUrl = this.apiConfig.sendNedbUrl;
      }

      if ([webSocket.CLOSED, webSocket.CLOSING].includes(webSocket.readyState)) {
        this.$notify({
          text: this.trans('notice.ncalayer_connection_error'),
          type: 'error',
        });
        // Ошибка при подключении к NCALayer. Перезапустите программу и обновите страницу.
        return;
      }
      webSocket.onopen = (event) => {
        callback = async (response) => {
          const signXml = response.responseObject;
          const res = await requestWrapper.call(this, {
            url: signUrl,
            method: 'post',
            data: { signXml },
          });
          if (res.error) {
            this.handleLoading(false);
          }
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };

      webSocket.onclose = (event) => {
        if (event.wasClean) {
          console.warn('connection has been closed');
        } else {
          this.$notify({
            text: this.trans('notice.ncalayer_connection_closed'),
            type: 'error',
          });
          // Соединение с NCALayer было прервано. Попробуйте авторизоваться еще раз или обновить страницу.
          this.handleLoading(false);
        }
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.handleLoading(false);
          }
        }
      };
    },
  },
};
