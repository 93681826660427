<template>
  <div class="s-voucher-queue__category-wrapper">
    <div
      v-for="(item, key) in items"
      :key="key"
      class="s-voucher-queue__category"
      :class="{'is-loading': loading}"
    >
      <div
        v-if="!isAdaptive"
        class="s-voucher-queue__category--num"
      >
        {{ key + 1 }}
      </div>
      <div class="s-voucher-queue__category--content">
        <div class="s-voucher-queue__category--body">
          <div class="s-voucher-queue__category--title-wrapper">
            <div
              v-if="isAdaptive"
              class="s-voucher-queue__category--num"
            >
              {{ key + 1 }}
            </div>
            <div class="s-voucher-queue__category--title">
              {{ item.name }}
            </div>
          </div>
          <div class="s-voucher-queue__category--desc">
            <i>{{ trans('queue.description') }}:</i>
            <div>{{ item.description }}</div>
          </div>
        </div>
        <div class="s-voucher-queue__category--bottom">
          <div class="s-voucher-queue__category--count-wrapper">
            <div> {{ countLabel }}</div>
            <div class="s-voucher-queue__category--count">
              {{ item.count }}
            </div>
          </div>
          <div class="s-voucher-queue__category--button">
            <q-btn
              v-if="!loading"
              color="green"
              outline
              rounded
              :no-caps="isAdaptive"
              class="s-w-100"
              :label="trans('button.see')"
              @click="$emit('showCategory', item.id)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VVoucherQueueCategories',
  props: {
    items: {
      type: Array,
      required: true,
    },
    countLabel: {
      type: String,
      required: true,
    },
    isAdaptive: Boolean,
    loading: Boolean,
  },
};

</script>
