<template>
  <div
    style="padding: 0 2rem;"
    class="d-flex"
    :class="$q.screen.width < 1145 ? 'flex-column align-items-start' : 'align-items-end'"
  >
    <div
      v-if="signData"
      class="s-w-100"
      style="padding: 1.5rem; max-width: 60rem; border: #EDEFF7 1px solid; border-radius: 5px"
    >
      <img
        v-if="signData.signQR"
        :src="signData.signQR"
        alt=""
      >
      <div class="mt-10 mb-10">
        <div
          class="d-flex mb-5"
          :class="$q.screen.width < 540 ? 'flex-column' : ''"
        >
          <div
            class="s-medium"
            style="margin-right: 5px;"
          >
            {{ trans('voucher.timesheet.status') }}:
          </div>
          <div>
            {{ signData.status }}
          </div>
        </div>
        <div
          v-if="signData.signerFullname"
          class="d-flex mb-5"
          :class="$q.screen.width < 540 ? 'flex-column' : ''"
        >
          <div
            class="s-medium"
            style="margin-right: 5px;"
          >
            {{ trans('voucher.timesheet.signerFullname') }}:
          </div>
          <div>
            {{ signData.signerFullname }}
          </div>
        </div>
        <div
          v-if="signData.signerIin"
          class="d-flex mb-5"
          :class="$q.screen.width < 540 ? 'flex-column' : ''"
        >
          <div
            class="s-medium"
            style="margin-right: 5px;"
          >
            {{ trans('voucher.timesheet.signerIin') }}:
          </div>
          <div>
            {{ signData.signerIin }}
          </div>
        </div>
        <div
          v-if="signData.signDate"
          class="d-flex mb-5"
          :class="$q.screen.width < 540 ? 'flex-column' : ''"
        >
          <div
            class="s-medium"
            style="margin-right: 5px;"
          >
            {{ trans('voucher.timesheet.signDate') }}:
          </div>
          <div>
            {{ signData.signDate }}
          </div>
        </div>
        <div
          v-if="signData.refusalDates"
          class="d-flex mb-5"
          :class="$q.screen.width < 540 ? 'flex-column' : ''"
        >
          <div
            class="s-medium"
            style="margin-right: 5px;"
          >
            {{ trans('voucher.timesheet.refusalDates') }}:
          </div>
          <div>
            {{ signData.refusalDates }}
          </div>
        </div>
        <div
          v-if="signData.reason"
          class="d-flex mb-5"
          :class="$q.screen.width < 540 ? 'flex-column' : ''"
        >
          <div
            class="s-medium"
            style="margin-right: 5px;"
          >
            {{ trans('voucher.timesheet.reason') }}:
          </div>
          <div>
            {{ signData.reason }}
          </div>
        </div>
        <div
          v-if="signData.comment"
          class="d-flex"
        >
          <div
            class="s-medium"
            style="margin-right: 5px;"
          >
            {{ trans('voucher.timesheet.comment') }}:
          </div>
          <div>
            {{ signData.comment }}
          </div>
        </div>
        <div
          v-if="signData.files"
          class="d-flex"
        >
          <div
            class="s-medium"
            style="margin-right: 5px;"
          >
            {{ trans('voucher.timesheet.files') }}:
          </div>
          <div v-html="signData.files" />
        </div>
      </div>
    </div>
    <div
      v-if="btnConfig?.length"
      class="s-group justify-content-end s-group--wider mt-10"
    >
      <template v-for="(btn, key) in btnConfig">
        <q-btn-dropdown
          v-if="btn.dropdown?.length"
          :key="key"
          :color="btn.color"
          :label="btn.label"
          menu-anchor="top end"
          menu-self="bottom end"
          :menu-offset="[0, 2]"
          transition-show="jump-up"
          transition-hide="jump-down"
          label-wider
          width="25"
          no-caps
        >
          <div
            class="d-flex flex-column"
            style="padding: 1rem;"
          >
            <q-btn
              v-for="(child, childKey) in btn.dropdown"
              :key="`${key}-${childKey}`"
              :color="child.color"
              :label="child.label"
              :class="key !== btn.dropdown?.length -1 ? 'mb-10' : ''"
              no-caps
              outline
              @click="handleBtn(child)"
            />
          </div>
        </q-btn-dropdown>
        <q-btn
          v-else
          :key="key"
          :color="btn.color"
          :label="btn.label"
          no-caps
          label-wider
          width="20"
          @click="handleBtn(btn)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { ATTENDANCE_TIME_SHEET_TRANSLATIONS } from '@vjs/balabaqsha/consts';
import { QBtnDropdown } from '@quasar/components';

export default {
  name: 'VTimeSheetVoucherInfo',
  components: {
    QBtnDropdown,
  },
  props: {
    signData: {
      type: Object,
      required: false,
    },
    btnConfig: {
      type: Array,
      required: false,
    },
    attendance: {
      type: Object,
      required: true,
    },
    reasons: {
      type: Array,
      required: true,
    },
    isParent: Boolean,
  },
  beforeCreate() {
    this.$trans.add(ATTENDANCE_TIME_SHEET_TRANSLATIONS);
  },
  methods: {
    handleOnReload() {
      this.$emit('reload');
    },
    async handleBtn(btn) {
      const { attendance, reasons } = this;
      if (btn.type === 'button') {
        if (btn['s-modal-config']) {
          const config = btn['s-modal-config'];
          const binds = config?.binds ?? {};
          binds.reasons = reasons;
          binds.attendance = attendance;
          const events = {};
          events.onApprove = this.handleOnReload;
          this.$sModal.open(
            btn['s-modal-config'].id ?? btn['s-modal-config'].component,
            { ...btn['s-modal-config'], binds, events },
          );
        }
      }
    },
  },
};
</script>
