<template>
  <div class="s-voucher-queue__item">
    <div
      v-if="!isAdaptive"
      class="s-voucher-queue__item--num"
    >
      {{ item.number }}
    </div>
    <div class="s-voucher-queue__item--content">
      <div class="s-voucher-queue__item--body">
        <div
          v-if="isAdaptive"
          class="s-voucher-queue__item--title-wrapper"
        >
          <div class="s-voucher-queue__item--num">
            {{ item.number }}
          </div>
          <div class="s-voucher-queue__item--title">
            {{ item.childName ?? 'test' }}
          </div>
          <div class="s-voucher-queue__item--id">
            <span> №: </span> {{ item.id }}
          </div>
        </div>
        <div class="s-voucher-queue__item--desc">
          <div
            v-for="(desc, key) in descItems"
            :key="key"
            class="s-voucher-queue__item--desc-item"
          >
            <div class="s-voucher-queue__item--desc-item-label">
              {{ desc.label }}
            </div>
            <div class="s-voucher-queue__item--desc-item-value">
              {{ desc.value }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="item.color === 'red'"
        class="s-voucher-queue__item--status"
        :class="`s-voucher-queue__item--status-${item.color}`"
      >
        <div> {{ trans('queue.voucher_queue_status') }}</div>
        <div> {{ item.status }} </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VVoucherQueueCategories',
  props: {
    item: {
      type: Object,
      required: true,
    },
    isAdaptive: Boolean,
  },
  computed: {
    descItems() {
      const { item } = this;
      const trans = key => this.trans(`queue.voucher_queue_statement_${key}`);
      const result = [];
      const addItm = (label, value) => { result.push({ label: trans(label), value }); };

      if (!this.isAdaptive) {
        addItm('childName', item.childName ?? 'test');
        addItm('id', item.id ?? 'test');
      }
      addItm('parentName', item.parentName ?? 'test');
      addItm('dateTime', item.dateTime ?? 'test');

      return result;
    },
  },
};

</script>
