<template>
  <form
    autocapitalize="off"
    autocomplete="off"
    spellcheck="false"
    @submit.prevent="request"
  >
    <div
      v-if="textHtml"
      class="mb-20 s-to-center s-medium"
      v-html="textHtml"
    />
    <q-option-group
      v-model="point"
      :options="points"
      type="radio"
      class="d-flex justify-content-between"
      inline
    />
    <q-input
      v-model="message"
      :label="trans(`csi.quiz_comment`)"
      filled
      class="mb-30"
      type="textarea"
      counter
      :maxlength="255"
    />
    <q-btn
      no-caps
      color="green"
      type="submit"
      :disable="!point"
      :label="trans('button.approve')"
      class="s-w-100"
    />
  </form>
</template>

<script>
import { Helper } from '@common/src/helpers';
import { QOptionGroup } from '@quasar/components';

export default {
  components: { QOptionGroup },
  props: {
    textHtml: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      required: true,
    },
    questionId: {
      type: Number,
      required: true,
    },
    points: {
      type: Array,
      default: () => (
        [...Array(5).keys()].map(itm => ({ label: itm + 1, value: itm + 1 }))
      ),
    },
  },
  data() {
    return {
      point: '',
      message: '',
    };
  },
  methods: {
    async request() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data: {
          point: this.point,
          message: this.message,
          csi_question_id: this.questionId,
        },
      });

      if (!res.error) {
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
