import {
  QDialog,
  QBtn,
} from '@quasar/components';
import VModal from '@vjs/components/VModal';

export default {
  name: 'VTextModal',
  components: {
    QDialog,
    QBtn,
    VModal,
  },
  props: {
    modal: Boolean,
    persistent: Boolean,
    center: true,
    title: String,
    text: String,
  },
  methods: {
    handleModal() {
      this.$emit('modal-close', false);
    },
  },
};
