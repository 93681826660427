<template>
  <div class="s-voucher-queue__statistic">
    <template v-if="isAdaptive">
      <div
        v-if="list?.length"
        class="mb-20"
      >
        <q-btn
          class="s-w-100 s-medium s-ico--animate sn-btn--text-left"
          :class="{'is-active': spoiler}"
          outline
          no-caps
          color="blue"
          label-wider
          icon-right="chevron-circle-down"
          use-fa-icons
          :label="trans('queue.statistic_title')"
          @click="spoiler = !spoiler"
        />
        <q-slide-transition>
          <div
            v-if="spoiler"
            class="s-voucher-queue__statistic--items"
          >
            <div
              v-for="(itm, idx) in list"
              :key="idx"
              class="s-voucher-queue__statistic--item"
              :class="{'is-loading': loading}"
            >
              <div class="s-voucher-queue__statistic--item-label">
                {{ itm.label }}
              </div>
              <div class="s-voucher-queue__statistic--item-value">
                {{ itm.value }}
              </div>
            </div>
          </div>
        </q-slide-transition>
      </div>
    </template>
    <template v-else>
      <div
        v-if="list?.length"
        class="s-title-section s-title--double-line s-f-18 s-medium"
        style="margin-bottom: 2rem;"
      >
        <span>{{ trans('queue.statistic_title') }}</span>
      </div>
      <div class="row align-items-stretch justify-content-center s-voucher-queue__statistic--items">
        <div
          v-for="(itm, idx) in list"
          :key="idx"
          class="col mb-20 d-flex justify-content-center"
        >
          <div
            class="s-voucher-queue__statistic--item"
            :class="{'is-loading': loading}"
          >
            <div class="s-voucher-queue__statistic--item-icon">
              <img :src="`${staticUrl}/balabaqsha/images/voucher/queue_${itm.img}.svg`">
            </div>
            <div class="s-voucher-queue__statistic--item-label">
              {{ itm.label }}
            </div>
            <div class="s-voucher-queue__statistic--item-value">
              {{ itm.value }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss">

</style>

<script>
import { genArray } from '@vjs/helpers';

export default {
  name: 'VVoucherQueueStatistic',
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
    staticUrl: String,
    loading: Boolean,
    isAdaptive: Boolean,
  },
  data() {
    return {
      spoiler: false,
    };
  },

  computed: {
    list() {
      if (this.loading) {
        return genArray({}, 6);
      }

      return this.items;
    },
  },
};
</script>
