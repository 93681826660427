<template>
  <q-form
    autocorrect="off"
    autocapitalize="off"
    autocomplete="off"
    spellcheck="false"
    @submit="approve"
  >
    <v-form-inform :message="trans('warning_text.voucher_application_revision_warn')" />

    <q-option-group
      v-model="docs"
      :options="refs"
      type="checkbox"
    />

    <q-input
      v-model="message"
      :label="trans(`label.type_message`)"
      filled
      class="mb-30"
      type="textarea"
      required
      counter
      :maxlength="1000"
    />

    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :disable="!message.length && !docs.length"
        type="submit"
        :label="trans('button.approve')"
      />
    </div>
  </q-form>
</template>

<script>
import { QInput, QOptionGroup } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {

  components: { QOptionGroup, QInput },

  props: {
    action: {
      type: String,
      default: '',
    },
    refs: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      message: '',
      docs: [],
    };
  },

  async mounted() {
    await this.$trans.add('warning_text');
  },
  methods: {
    async approve() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data: { message: this.message, docs: this.docs },
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onApprove');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
