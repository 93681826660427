import _ from 'lodash';
import {
  QBtn,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import VBulletinItemModal from './VBulletinItemModal.vue';

export default {
  name: 'VBulletinItem',
  components: {
    QBtn,
    VBulletinItemModal,
  },
  props: {
    organizationId: {
      type: Number,
      required: true,
    },
    organization: {
      type: Object,
      default: () => ({
        address: '',
        name: '',
        type: '',
      }),
    },
    params: {
      type: Array,
      default: () => ([
        {
          key: '',
          value: '',
        },
      ]),
    },
    groupName: String,
    educationYear: String,
    orgType: String,
    ages: String,
    publishDate: String,
    expiredAt: String,
    dateEnd: String,
    visibleRequestButton: Boolean,
    cancelDate: String,
    uriStatements: {
      type: String,
      required: true,
    },
    uriStatementRequest: {
      type: String,
      required: true,
    },
    uriStatementDiseaseRequest: {
      type: String,
      required: true,
    },
    uriStatementSanatoriaRequest: {
      type: String,
      required: true,
    },
    uriStatementVoRequest: {
      type: String,
      default: null,
    },
    diseases: {
      type: String,
      default: '',
    },
    sanatorium: {
      type: String,
      default: '',
    },
    typeOfTab: {
      type: String,
      default: 'all',
    },
    correction: {
      type: Boolean,
      default: () => false,
    },
    sanatoria: {
      type: Boolean,
      default: () => false,
    },
    nedbIntegration: {
      type: Boolean,
      default: false,
    },
    isVoucherActive: {
      type: Boolean,
      default: false,
    },
    agesList: String,
    loading: Boolean,
  },
  data() {
    return {
      modalBulletinItem: false,
      modalBulletinItemStatements: [],
      statementsLoading: false,
    };
  },
  methods: {
    // Запрашивает список детей
    async requestStatements() {
      this.statementsLoading = true;
      const { correction, sanatoria } = this;
      const statementsRes = await requestWrapper.call(this, {
        url: this.uriStatements,
        params: {
          id: this.organizationId,
          correction,
          sanatoria,
        },
      });
      this.modalBulletinItemStatements = (
        _.isArray(statementsRes.data) && statementsRes.data
      ) || [];
      this.modalBulletinItem = true;
      this.statementsLoading = false;
    },
  },
};
