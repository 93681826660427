<template>
  <div class="s-pos-relative-position s-voucher-queue__items">
    <div
      class="s-title-link s-medium s-c-blue"
      :class="{'s-title-link--small': $q.screen.width < 768}"
    >
      <div
        class="s-title-link__link"
        @click="$emit('handleBackBtn')"
      >
        <q-icon
          name="arrow-left"
          icon-class="s-ico-fa"
        />
        <q-tooltip>{{ trans('button.back') }}</q-tooltip>
      </div>
      {{ categoryName }}
    </div>
    <div class="row mb-30">
      <div class="col-lg-5 col-md-7 col-sm-12">
        <q-input
          v-model="searchValue"
          class="s-voucher-queue__items-input"
          :debounce="300"
          :placeholder="trans('placeholder.search')"
          :label="trans('label.search_by_keyword')"
        >
          <template
            v-if="$q.screen.width > 400"
            v-slot:prepend
          >
            <div class="s-c-white s-b-blue">
              <q-icon
                name="search"
                size="2rem"
              />
            </div>
          </template>
        </q-input>
      </div>
      <div
        v-if="withYearFilter"
        class="col-lg-3 col-md-5 col-sm-12"
      >
        <v-wrapper-q-select
          v-model="year"
          :options="yearOptions"
          :extra-binds="{
            label: trans('queue.child_birth_age'),
            hideBottomSpace: true,
          }"
        />
      </div>
      <div
        v-if="withStopList"
        class="col-lg-4 col-md-5 col-sm-12 align-content-center mt-25"
      >
        <q-btn
          no-caps
          width="26"
          :outline="!hideStopList"
          color="orange"
          class="s-w-100"
          :label="trans(`queue.voucher_stop_list_${hideStopList ? 'show' : 'hide'}`)"
          @click="hideStopList = !hideStopList"
        />
      </div>
    </div>

    <div class="s-voucher-queue__items-wrapper">
      <v-voucher-queue-item
        v-for="(item, key) in paginate"
        :key="key"
        :item="item"
        :is-adaptive="isAdaptive"
      />
    </div>
    <div
      v-if="countOfPages"
      class="row justify-content-center mt-30"
    >
      <div class="col-auto">
        <q-pagination
          v-model="currentPage"
          :max="countOfPages"
          :max-pages="5"
          boundary-links
          :input="countOfPages > 5"
          input-class="s-c-blue"
          @input="$emit('scrollTop')"
        />
      </div>
    </div>
    <v-no-results
      v-if="empty"
      image="queue"
      :title="trans('warning_text.no_result_queue_voucher_title')"
      :text="trans('warning_text.no_result_queue_voucher_text', {url: uriSendStatement})"
    />
  </div>
</template>

<script>
import { QIcon, QPagination } from '@quasar/components';
import { genArray } from '@vjs/helpers';
import VNoResults from '@vjs/components/VNoResults';
import VVoucherQueueItem from './VVoucherQueueItem';

export default {
  name: 'VVoucherQueueCategory',
  components: {
    QIcon,
    QPagination,
    VVoucherQueueItem,
    VNoResults,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    withStopList: Boolean,
    withYearFilter: Boolean,
    categoryName: String,
    uriSendStatement: String,
    isAdaptive: Boolean,
  },
  data() {
    return {
      searchValue: '',
      currentPage: 1,
      countByPage: 10,
      hideStopList: false,
      year: window.currentDayHelper.year,
    };
  },
  computed: {
    yearOptions() {
      const result = [];
      const makeOpt = (val) => { result.push({ id: val, name: val }); };
      const { year } = window.currentDayHelper;
      makeOpt(year);
      _.forEach(Array(6), (itm, key) => {
        makeOpt(year - key - 1);
      });
      return result;
    },
    // Мониторит список на пустоту
    empty() {
      return this.itemsFiltered.length === 0 && !this.loading;
    },
    // Отдаёт кол-во очереди на страницу
    paginate() {
      if (this.loading) {
        return genArray({
          childFullName: '',
          dateSendStatement: 0,
          expiredDate: 0,
          privilegeClient: {
            key: 1,
            value: '',
          },
          queueFlagStyle: '',
          queueNumber: 0,
          userFullName: '',
        }, 4);
      }

      const current = (this.currentPage - 1) * this.countByPage;
      return this.itemsFiltered.slice(current, current + this.countByPage);
    },
    // Считает кол-во страниц в пагинации по выставленным в data параметрам
    countOfPages() {
      let count = parseInt(this.itemsFiltered.length / this.countByPage, 10);
      const remains = this.itemsFiltered.length % this.countByPage;
      count += remains !== 0 ? 1 : 0;
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
    itemsFiltered() {
      let items = _.cloneDeep(this.items);

      if (this.searchValue !== '') {
        const searchStr = this.searchValue.toLowerCase();
        items = items.filter((a) => {
          const childName = a.childName || '';
          const parentName = a.parentName || '';
          return childName.toLowerCase().indexOf(searchStr) !== -1
            || parentName.toLowerCase().indexOf(searchStr) !== -1;
        });
      }

      if (this.year && this.withYearFilter) {
        items = items.filter(itm => itm.birthYear === this.year);
      }

      if (this.hideStopList && this.withStopList) {
        items = items.filter(itm => itm.color !== 'red');
      }

      return items;
    },
  },
};

</script>
