import {
  QForm,
  QInput,
  QBtn,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import VFormInform from '@vjs/components/VFormInform';
import VCountdownForSms from '../VCountdownForSms';
import Validation from '../mixins/Validation';


export default {
  name: 'VStepAuthentication',

  components: {
    VFormInform,
    VCountdownForSms,
    QForm,
    QInput,
    QBtn,
  },

  mixins: [Validation],

  props: {
    uriSendPhone: {
      type: String,
      required: true,
    },
    uriSendCode: {
      type: String,
      required: true,
    },
    uriSendAuth: {
      type: String,
      required: true,
    },
    uriSendRestorePhone: {
      type: String,
      required: true,
    },
    uriSendRestoreCode: {
      type: String,
      required: true,
    },
    uriSendRestorePassword: {
      type: String,
      required: true,
    },
    codeSize: {
      type: [Number, String],
      default: 4,
    },
  },

  data() {
    return {
      step: 1,
      /*
      *   1: Ввод номера телефона;
      *   - Если не зарегистрирован: 2, 3 -
      *   2: Проверка телефона по смс;
      *   3: Ввод пароля;
      *   - Если есть номер в базе: 4 -
      *   4: Ввод телефона / пароля;
      *   - Если забыл пароль 2/5, 3/6 -
      */
      loading: false,
      form: {
        phone: '',
        code: '',
        password: '',
        passwordConfirm: '',
      },
    };
  },

  beforeCreate() {
    // Запрос переводов
    this.$trans.add(['auth', 'label', 'button', 'validation', 'notice', 'response']);
  },

  created() {
    // Слушатели для направляющих функций
    this.$eventHub.$on('SET_PREV_STEP', this.prevStep);
    this.$eventHub.$on('SET_NEXT_STEP', this.nextStep);
  },

  beforeDestroy() {
    // Вырубаем слушатели
    this.$eventHub.$off('SET_PREV_STEP', this.prevStep);
    this.$eventHub.$off('SET_NEXT_STEP', this.nextStep);
  },

  computed: {
    // Валидирует кнопку отправки формы
    buttonDisable() {
      switch (this.step) {
        case 1: return this.form.phone.length < 18;
        case 2:
        case 5: return this.form.code.length < this.codeSize;
        case 4: return this.form.password === '';
        case 3:
        case 6: return this.form.password.length < 8
                || this.form.password !== this.form.passwordConfirm;
        default: return true;
      }
    },
  },

  watch: {
    // Проверка доступа к следующему глобальному шагу (если пароли совпадают)
    buttonDisable(val) {
      this.$store.commit('SET_ACCESS_STEP', {
        step: 2,
        access: (this.step === 3 && !val)
                || (this.step === 4 && !val)
                || (this.step === 6 && !val),
      });
    },

    step(val) {
      this.$store.commit('SET_BACKWARD_STEP', {
        step: 2,
        backward: val !== 1,
      });
    },
  },

  methods: {
    // Общая отправка формы
    async requestForm() {
      switch (this.step) {
        case 1:
          await this.requestForCheckPhone();
          break;
        case 2:
        case 5:
          await this.requestForCheckCode();
          break;
        default: break;
      }
    },

    // Провека телефона через отправку смс
    // (если номер найден. то переключает на шаг авторизации)
    async requestForCheckPhone() {
      this.$emit('loading', true);
      const checkRes = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriSendPhone,
        data: {
          phone: this.form.phone,
        },
      });
      this.$emit('loading', false);

      if (checkRes.error && checkRes.data.action === 'authorization') {
        this.$notify({
          type: 'warn',
          title: this.trans('notice.warn'),
          text: this.trans('auth.founded_account'),
        });
        this.step = 4;
        return true;
      }

      if (!checkRes.error) {
        this.step = 2;
        return true;
      }

      return false;
    },

    // Проверяет код полученный по смс
    async requestForCheckCode() {
      this.$emit('loading', true);
      const checkRes = await requestWrapper.call(this, {
        method: 'post',
        url: this.step === 2 ? this.uriSendCode : this.uriSendRestoreCode,
        data: {
          phone: this.form.phone,
          code: this.form.code,
        },
      });
      this.$emit('loading', false);

      if (!checkRes.error) {
        this.step = this.step === 2 ? 3 : 6;
        return true;
      }

      return false;
    },

    // Авторизация
    async requestAuth() {
      const formData = new FormData();
      formData.append('phone', this.form.phone);
      formData.append('type', 'phone');
      formData.append('password', this.form.password);
      this.$emit('loading', true);
      const authRes = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriSendAuth,
        data: formData,
      });

      this.$emit('loading', false);

      if (authRes.error && authRes.data.errors && authRes.data.errors.login) {
        this.$notify({
          type: 'error',
          title: this.trans('notice.error'),
          text: authRes.data.errors.login[0],
        });
        return false;
      }

      if (!authRes.error && authRes.csrfToken) {
        window.core_project.csrfToken = authRes.csrfToken;
      }

      return !authRes.error;
    },

    // Отправка номера для восстановления пароля
    async requestRestorePhone() {
      this.$emit('loading', true);
      const checkRes = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriSendRestorePhone,
        data: {
          phone: this.form.phone,
        },
      });
      this.$emit('loading', false);

      if (!checkRes.error) {
        this.step = 5;
      }
    },

    // Отправка нового пароля в модуле восстановления
    async requestRestorePassword() {
      this.$emit('loading', true);

      const passwordRes = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriSendRestorePassword,
        data: {
          phone: this.form.phone,
          password: this.form.password,
          code: this.form.code,
        },
      });
      this.$emit('loading', false);

      return !passwordRes.error;
    },

    // контролирует переход на предыдущий локальный шаг
    prevStep() {
      this.step = 1;
      this.form.password = '';
      this.form.passwordConfirm = '';
      this.form.code = '';
      this.validation = {};
    },

    // контролирует переход на следующий внешний шаг
    async nextStep() {
      if (this.step === 3) {
        this.$emit('send-data', {
          fromRegistration: true,
          phone: this.form.phone,
          password: this.form.password,
          code: this.form.code,
        });
        this.$store.commit('SET_NEXT_STEP');
      } else if (this.step === 4) {
        if (await this.requestAuth()) {
          this.$emit('send-data', {
            fromRegistration: false,
            phone: this.form.phone,
          });
          this.$store.commit('SET_NEXT_STEP');
        }
      } else if (this.step === 6) {
        if (await this.requestRestorePassword()) {
          this.$notify({
            type: 'success',
            title: this.trans('notice.message'),
            text: this.trans('response.password_changed'),
          });
          this.$emit('send-data', {
            fromRegistration: false,
            phone: this.form.phone,
          });
          this.$store.commit('SET_NEXT_STEP');
        }
      }
    },
  },
};
