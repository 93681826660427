import { QBtn, QTooltip, QPopupProxy } from '@quasar/components';
import VConfirmDialog from '@vjs/modals/VConfirmDialog';
import { requestWrapper, textUtils, url } from '@vjs/helpers';
import VApplicationItemModal from './VApplicationItemModal';
import VApplicationLgotsModal from './VApplicationLgotsModal';
import VApplicationLangModal from './VApplicationLangModal';
import VApplicationRemovePriorityModal from './VApplicationRemovePriorityModal';

export default {
  name: 'VApplicationItem',

  components: {
    QBtn,
    QTooltip,
    QPopupProxy,
    VConfirmDialog,
    VApplicationItemModal,
    VApplicationLgotsModal,
    VApplicationLangModal,
    VApplicationRemovePriorityModal,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    informText: {
      type: String,
      default: '',
    },
    identifier: {
      type: Number,
      required: true,
    },
    flag: String,
    date: String,
    statementsCount: String,
    lgotBtn: Object,
    langBtn: {
      type: Object,
      default: () => ({}),
    },
    removePriorityBtn: {
      type: Object,
      default: () => ({}),
    },
    removeSocialBtn: {
      type: Object,
      default: () => ({}),
    },
    editStatementBtn: {
      type: Object,
      default: () => ({}),
    },
    sendRetirementRequestBtn: {
      type: Object,
      default: () => ({
        active: false,
      }),
    },
    qrSignBtn: {
      type: Object,
      default: () => ({
        disable: true,
        signId: 'v-modal-egov-sign',
        ncaLayerAction: '',
        getData: '',
        updateQr: '',
      }),
    },
    locked: Boolean,
    isNotInterested: Boolean,
    hasCorrOrgQueue: Boolean,
    removeSuccess: Boolean,
    desc: {
      type: Array,
      default: () => ([]),
    },
    schools: {
      type: Array,
      default: () => ([]),
    },
    uriRemove: {
      type: String,
      default: '',
    },
    uriInterest: {
      type: String,
      required: true,
    },
    uriStatementContinue: {
      type: String,
      required: true,
    },
    uriUpdateLgots: {
      type: String,
      default: '',
    },
    uriGetLgotTypes: {
      type: String,
    },
    uriGetLgotTypeDocuments: {
      type: String,
    },
    uriGetStatementSchoolEdit: {
      type: String,
      default: '',
    },
    uriCheckSpecialType: {
      type: String,
      default: '',
    },
    steps: {
      type: Array,
      default: () => ([]),
    },
    buttonConfig: {
      type: Array,
      default: () => ([]),
    },
    isCorrection: Boolean,
    isSanatorium: Boolean,
    isVoucher: Boolean,
    nebdIntegration: Boolean,
    departmentId: null,
    childId: null,
    socStatusId: null,
  },

  beforeCreate() {
    this.$trans.add(['organization', 'button', 'statement', 'notice', 'label', 'title']);
  },

  data() {
    return {
      modalConfirmRemove: false,
      modalContinue: false,
      modalLgots: false,
      modalLang: false,
      modalRemovePriority: false,
      loadingRemove: false,
      loadingInterest: false,
      loading: false,
      schoolsTrigger: false,
    };
  },
  async mounted() {
    await this.$trans.add([
      'statement',
      'label',
      'button',
    ]);
  },
  computed: {
    isActionsVisible() {
      if (this.isVoucher) {
        return this.buttonConfig?.length > 0;
      }

      return (this.lgotBtn && this.lgotBtn.canUpdate && !this.isCorrection && this.uriUpdateLgots !== '')
        || (this.langBtn.active)
        || (this.removePriorityBtn.active && this.uriRemove !== '')
        || (this.removeSuccess)
        || (this.removeSocialBtn.active && this.removeSocialBtn.url)
        || (this.editStatementBtn.active && (this.editStatementBtn.url || this.editStatementBtn.submitUrl));
    },
  },
  methods: {
    async handleBtn(btn) {
      if (btn.disable) {
        if (btn.disabledNotify) {
          this.$notify(btn.disabledNotify);
        }
        return null;
      }
      if (btn.type === 'button') {
        const config = btn['s-modal-config'];
        const binds = config?.binds ?? {};

        const events = {};

        if (config.removeOnSuccess) {
          const func = () => {
            this.$emit('removed', this.identifier);
          };
          events.onApprove = func;
          events.onSuccess = func;
        }

        this.$sModal.open(
          config.component,
          { ...config, binds, events },
        );
      }
      return null;
    },
    handleUpdateLgotModal() {
      if (this.lgotBtn.disable === true) {
        this.$notify({
          text: this.trans('statement.already_send_update_lgot'),
          type: 'warn',
        });
        return;
      }
      this.modalLgots = true;
    },
    handleUpdateLangModal() {
      if (this.langBtn.active) {
        this.modalLang = true;
      }
    },
    handleRemovePriority() {
      this.modalRemovePriority = true;
    },
    handleConfirmRemovePriority() {
      this.modalLang = true;
      this.modalRemovePriority = false;
    },
    async setInterest(uri) {
      this.loadingInterest = true;
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: (typeof uri === 'string' && uri) || url(`${this.uriInterest}/${this.identifier}`),
      });

      if (!res.error) {
        if (res.data && res.data.status && res.data.steps) {
          this.$emit('unlock', res.data.steps);
        }

        this.$emit('is-interest');
      }

      this.loadingInterest = false;
      return !res.error;
    },
    async directionApprove(uri) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: uri,
      });
      if (!res.error) {
        console.log(res);
      }
      this.$globalLoading.hide();
    },
    capitalizeByWords: textUtils.capitalizeByWords,
    getIconByStatus(status = '') {
      switch (status) {
        case 'done':
          return 'check-circle';
        case 'waiting':
          return 'waiting';
        case 'inactive':
        case 'failed':
          return 'clock';
        default:
          return '';
      }
    },
    async removeApplication() {
      this.loadingRemove = true;
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.sendRetirementRequestBtn.active
          ? this.sendRetirementRequestBtn.url : this.uriRemove,
        data: {
          statement_id: this.identifier,
        },
      });
      this.loadingRemove = false;

      if (!res.error) {
        this.modalConfirmRemove = false;
        this.$emit('removed', this.identifier);
      }
    },
    async controlDoing(name, action, stepIndex, ctrlIndex) {
      let res;
      switch (name) {
        case 'interested':
          res = await this.setInterest(action);

          if (res && stepIndex !== undefined) {
            this.steps[stepIndex].controls = this.steps[stepIndex].controls
              .filter(itm => itm.name !== name);
          }

          break;
        case 'direction_extend':
          this.modalContinue = true;
          this.stepControlsCoor = [stepIndex, ctrlIndex];
          break;
        case 'approve_direction':
        case 'reject_direction':
          this.$sModal.open('v-modal-confirm', {
            title: this.trans('notice.are_you_sure'),
            component: 'v-modal-confirm',
            size: 's',
            binds: { action },
          });
          break;
        default:
          break;
      }
    },
    setLoading(name) {
      switch (name) {
        case 'interested':
          return this.loadingInterest;
        default:
          return false;
      }
    },
    setControlSuccessFromItemModal() {
      const stepControl = this.steps[this.stepControlsCoor[0]].controls[this.stepControlsCoor[1]];
      stepControl.disabled = true;
      stepControl.tooltip = this.trans('notice.can_not_extend_direction');
    },
    handleEditModal(editStatementBtn) {
      {
        const modal = editStatementBtn.isVoucher ? 'v-edit-statement-schools-voucher-modal' : 'v-edit-statement-schools-modal';
        const size = editStatementBtn.isVoucher ? 'big' : 'large';

        this.$sModal.open(modal, {
          id: modal,
          title: this.trans('title.select_statement_schools'),
          component: modal,
          size,
          binds: {
            getDataUrl: editStatementBtn.getDataUrl,
            submitUrl: editStatementBtn.submitUrl,
            schoolLangUrl: editStatementBtn.schoolLangUrl,
            statementId: editStatementBtn.id,
          },
        });
      }
    },
  },
};
