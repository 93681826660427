import {
  QTable,
  QTr,
  QTd,
  QTh,
  QTooltip,
} from '@quasar/components';

export default {
  name: 'VVoucherFundingKindergartenGroupListFinal',
  components: {
    QTable,
    QTr,
    QTd,
    QTh,
    QTooltip,
  },
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        getTable: '',
        getRefs: '',
      }),
    },
  },
  data() {
    return {
      table: {
        rows: [],
        cols: [],
      },
      filters: {
        locality: null,
        year: null,
        month: null,
      },
      refs: {
        locality: [],
        year: [],
        month: {},
      },
    };
  },
  async mounted() {
    await this.$trans.add(['label']);
    await requestGetDataHelper(this, {
      url: this.apiConfig.getRefs,
      hardSet: true,
      variableName: 'refs',
    });
    this.filters.locality = this.refs.locality[0]?.value;
    this.filters.year = this.refs.year[0]?.value;
    this.filters.month = this.refs.month[this.filters.year][0]?.value;
    await this.loadTable();
  },
  methods: {
    async handleYear() {
      this.filters.month = this.refs.month[this.filters.year][0]?.value;
      await this.loadTable();
    },
    async loadTable() {
      await requestGetDataHelper(this, {
        url: this.apiConfig.getTable,
        hardSet: true,
        variableName: 'table',
        params: this.filters,
      });
    },
  },
};
