import { Helper } from '@common/src/helpers';
import axios from 'axios';

export default {
  name: 'v-modal-inform-critical',
  props: {
    textHtml: {
      type: String,
      default: '',
    },
    informConfig: {
      type: Object,
      default: () => ({
        color: undefined,
        text: '',
      }),
    },
    buttonConfig: {
      type: Object,
      default: () => ({
        color: 'orange',
        label: null,
        type: 'button',
        exportName: '',
        exportNotify: '',
      }),
    },
    action: {
      type: String,
      default: '',
    },
  },
  methods: {
    async request() {
      const { type } = this.buttonConfig;
      switch (type) {
        case 'download': await this.handleDownload(); break;
        case 'button':
        default:
          await this.handleSubmit(); break;
      }
    },
    async handleSubmit() {
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$emit('onClose');
    },
    async handleDownload() {
      this.$globalLoading.show();
      const {
        $notify, action, trans, buttonConfig: { exportName, exportNotify },
      } = this;
      await axios({
        url: action,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;

        fileLink.setAttribute('download', exportName || trans('label.default_export_name'));
        document.body.appendChild(fileLink);

        fileLink.click();
        if (exportNotify) {
          $notify({
            type: 'success',
            text: exportNotify,
          });
        }
      }).catch(async (err) => {
        let parsedError = null;
        await err.response.data.text().then((text) => {
          parsedError = JSON.parse(text);
        });
        if (parsedError.errorType === 'success') {
          $notify({
            type: 'success',
            text: parsedError.message,
          });
        } else {
          $notify({
            type: 'error',
            title: window.L.error,
            text: parsedError.message || this.trans('notice.error_on_server'),
          });
        }
      })
        .finally(() => {
          this.$globalLoading.hide();
          this.$emit('onClose');
        });
    },
  },
};
