<template>
  <div>
    <div class="s-plain-text">
      <p class="s-medium">
        {{ trans('label.docs_criteries') }}:
      </p>
      <ul class="mb-20">
        <li>{{ trans('label.file_formats') }};</li>
        <li>{{ trans('label.file_size') }};</li>
        <li>{{ trans('label.file_dimensions') }};</li>
      </ul>
    </div>

    <q-select
      v-model="lgotType"
      class="mb-10"
      popup-content-class="sn-menu-short"
      :options="lgotTypeOptions"
      :label="trans('label.lgot_type')"
      required
      @input="getLgotTypeDocuments()"
    />
    <q-slide-transition>
      <div
        v-show="Object.keys(lgotTypeDocuments).length"
      >
        <div
          v-for="(item, key) in lgotTypeDocuments"
          :key="key"
          class="mt-10 mb-20"
        >
          <div class="sn-field__label mb-15">
            {{ lgotTypeDocumentRules[key].label }}:
            <i v-if="lgotTypeDocumentRules[key].required === true">*</i>
          </div>
          <q-btn
            v-if="!item"
            class="s-w-100 form-upload__button"
            icon="clip"
            color="blue"
            type="button"
            :label="trans('button.overview')"
            label-wider
            @click="triggerFile(key)"
          />
          <q-btn
            v-if="item"
            icon="close"
            class="s-w-100 s-btn--border sn-btn--ellipsis"
            :label="item.name"
            label-wider
            type="button"
            @click="clearFile(key)"
          />
        </div>
      </div>
    </q-slide-transition>

    <input
      ref="fileInput"
      class="d-none"
      type="file"
      accept="application/msword, application/pdf, image/*"
      @change="fileChange()"
    >
    <input
      ref="specialFileInput"
      class="d-none"
      type="file"
      accept="application/msword, application/pdf, image/*"
      @change="fileChange(true)"
    >

    <div
      v-show="showNedbSpecialTypes"
    >
      <q-select
        v-model="statement.nedbCategory"
        :label="trans('label.nedb_category')"
        :options="nedbCategories"
        option-label="label"
      />
      <q-input
        v-model="statement.nedbConsultationDate.value"
        mask="##.##.####"
        :label="trans('label.nedb_consultation_date')"
        :disable="!statement.nedbConsultationDate.enabled"
        fill-mask
        :required="nedbSpecialTypesRequired"
      />
      <q-input
        v-model="statement.nedbConclusion.value"
        :label="trans('label.nedb_conclusion')"
        :disable="!statement.nedbConclusion.enabled"
        :required="nedbSpecialTypesRequired"
      />
      <q-input
        v-model="statement.nedbRecommendation.value"
        :label="trans('label.nedb_recommendation')"
        :disable="!statement.nedbRecommendation.enabled"
        :required="nedbSpecialTypesRequired"
      />
      <q-input
        v-model="statement.nedbConclusionNumber.value"
        :label="trans('label.nedb_conclusion_list_number')"
        :disable="!statement.nedbConclusionNumber.enabled"
        :required="nedbSpecialTypesRequired"
      />
      <div
        v-if="statement.needDiseaseDocument"
        class="sn-field__label mb-15"
      >
        {{ trans('statement.disease-document') }}:<i>*</i>
      </div>
      <q-btn
        v-if="!statement.statementDocumentDisease && statement.needDiseaseDocument"
        class="s-w-100 form-upload__button"
        icon="clip"
        color="blue"
        type="button"
        :label="trans('button.overview')"
        label-wider
        @click="triggerFile('statementDocumentDisease', true)"
      />
      <q-btn
        v-if="statement.statementDocumentDisease && statement.needDiseaseDocument"
        v-model="statement.statementDocumentDisease"
        icon="close"
        class="s-w-100 s-btn--border sn-btn--ellipsis"
        :label="statement.statementDocumentDisease.name"
        label-wider
        type="button"
        @click="clearFile('statementDocumentDisease', true)"
      />
    </div>

    <div class="row justify-content-between">
      <div class="col col-sm-auto sn--text-center mt-5 mb-5">
        <q-btn
          width="20"
          :label="trans('button.cancel')"
          label-wider
          color="grey"
          @click="$emit('onClose')"
        />
      </div>
      <div class="col col-sm-auto sn--text-center mt-5 mb-5">
        <q-btn
          width="20"
          :label="trans('button.send')"
          color="green"
          :disable="!validate"
          @click="send"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  QBtn, QInput, QSelect, QSlideTransition,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import eventHub from '@vjs/config/eventHub';
import _ from 'lodash';

export default {
  name: 'VModalStatementLgots',
  components: {
    QInput,
    QSelect,
    QSlideTransition,
    QBtn,
  },
  props: {
    uriUpdateLgots: {
      type: String,
      required: true,
    },
    uriGetLgotTypes: {
      type: String,
      required: true,
    },
    uriGetLgotTypeDocuments: {
      type: String,
      required: true,
    },
    uriCheckSpecialType: {
      type: String,
      required: true,
    },
    identifier: {
      type: Number,
      required: true,
    },
    nebdIntegration: {
      type: Boolean,
      default: false,
    },
    departmentId: {
      type: Number,
      default: null,
    },
    childId: {
      type: Number,
      default: null,
    },
    socStatusId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      lgotTypeOptions: [],
      lgotTypeDocuments: {},
      lgotTypeDocumentRules: {},
      withoutDocumentsAndRules: false,
      lgotType: {
        value: 0,
        label: this.trans('statement.socStatuses.no_lgot'),
      },
      activeFile: null,
      showNedbSpecialTypes: false,
      statement: {
        nedbCategory: null,
        nedbConsultationDate: {
          enabled: false,
          value: null,
        },
        nedbConclusion: {
          enabled: false,
          value: null,
        },
        nedbRecommendation: {
          enabled: false,
          value: null,
        },
        nedbConclusionNumber: {
          enabled: false,
          value: null,
        },
        needDiseaseDocument: false,
        statementDocumentDisease: null,
      },
      nedbCategories: [],
      nedbSpecialTypesRequired: false,
    };
  },
  computed: {
    validate() {
      if (this.showNedbSpecialTypes) {
        if (this.nedbSpecialTypesRequired || this.statement.nedbCategory) {
          return this.statement.nedbCategory && this.statement.statementDocumentDisease !== null
            && this.statement.nedbConsultationDate.value !== null
            && this.statement.nedbConclusion.value !== null
            && this.statement.nedbRecommendation.value !== null
            && this.statement.nedbConclusionNumber.value !== null;
        }
        return true;
      }
      let lgotDocumentsValidate = true;
      _.forEach(this.lgotTypeDocuments, (file, key) => {
        if (this.lgotTypeDocumentRules[key].required === true && file === null) {
          lgotDocumentsValidate = false;
        }
      });

      return (this.lgotType.value !== null && this.lgotType.value !== 0
        && Object.keys(this.lgotTypeDocuments).length > 0
        && lgotDocumentsValidate) || this.withoutDocumentsAndRules;
    },
  },
  beforeCreate() {
    this.$trans.add(['organization', 'button', 'statement', 'notice', 'label']);
  },

  async mounted() {
    await this.$trans.add([
      'statement',
      'label',
      'button',
    ]);
    await this.loadLgotTypeOptions();
    await this.getLgotTypeDocuments();
  },

  methods: {
    clearFile(filename, special = false) {
      if (special) {
        this.statement.statementDocumentDisease = null;
      } else {
        this.lgotTypeDocuments[filename] = null;
      }
    },
    fileChange(special = false) {
      const file = special
        ? this.$refs.specialFileInput.files[0] : this.$refs.fileInput.files[0];
      if (file) {
        if (special) {
          this.statement.statementDocumentDisease = file;
        } else {
          this.lgotTypeDocuments[this.activeFile] = file;
        }
      }
      this.activeFile = null;
      this.$refs.fileInput.value = null;
    },
    triggerFile(filename, special = false) {
      this.activeFile = filename;
      if (special) {
        this.$refs.specialFileInput.click();
      } else {
        this.$refs.fileInput.click();
      }
    },
    handleModal() {
      this.$emit('onClose');
    },
    async getLgotTypeDocuments() {
      this.$globalLoading.show();
      const response = await requestWrapper({
        method: 'GET',
        url: this.uriGetLgotTypeDocuments,
        params: {
          lgotType: this.lgotType.value,
          departmentId: this.departmentId,
        },
      });

      if (response.message) {
        this.$notify({
          text: response.message,
          type: 'error',
        });
        this.lgotTypeDocuments = {};
        this.lgotTypeDocumentRules = {};
      } else {
        this.lgotTypeDocuments = response.data.documents;
        this.lgotTypeDocumentRules = response.data.documentRules;
        this.withoutDocumentsAndRules = response.data.withoutDocumentsAndRules ?? false;

        if (this.nebdIntegration) {
          await this.checkSpecialType();
        }
      }
      this.$globalLoading.hide();
    },
    async loadLgotTypeOptions() {
      this.$globalLoading.show();
      const response = await requestWrapper({
        method: 'GET',
        url: this.uriGetLgotTypes,
      });

      if (response.message) {
        this.$notify({
          text: response.message,
          type: 'error',
        });
      }
      this.lgotTypeOptions = response.data;
      this.lgotType = {
        value: 0,
        label: this.trans('statement.socStatuses.no_lgot'),
      };
      this.$globalLoading.hide();
    },
    async send() {
      this.$globalLoading.show();
      const formData = new FormData();
      formData.append('lgot_type', this.lgotType.value);
      formData.append('statement_id', this.identifier);
      _.forEach(this.lgotTypeDocuments, (file, name) => {
        formData.append(name, file);
      });

      if (this.showNedbSpecialTypes && this.statement.nedbCategory?.value) {
        formData.append('diseases', parseInt(this.statement.nedbCategory.value, 10));
        formData.append('document_disease', this.statement.statementDocumentDisease);
        formData.append('consultation_date', this.statement.nedbConsultationDate.value);
        formData.append('conclusion', this.statement.nedbConclusion.value);
        formData.append('recommendation', this.statement.nedbRecommendation.value);
        formData.append('conclusion_list_number', this.statement.nedbConclusionNumber.value);
      }

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriUpdateLgots,
        data: formData,
      });

      if (res.error) {
        this.$globalLoading.hide();
        if (res.data.errors && res.errorType === 422) {
          this.$notify({
            text: res.data.errors[Object.keys(res.data.errors)[0]][0],
            type: 'error',
          });
        }
        return;
      }

      if (!res.error) {
        this.handleModal();
        eventHub.$emit('updatedLgot', true);
      }

      this.$globalLoading.hide();
    },
    async checkSpecialType() {
      this.$globalLoading.show();
      const response = await requestWrapper({
        method: 'POST',
        url: this.uriCheckSpecialType,
        params: {
          lgotType: this.lgotType.value,
          childId: this.childId,
          departmentId: this.departmentId,
        },
      });
      if (!response.error) {
        if (response.data.category) {
          this.nedbCategories = response.data.category;
          this.statement.nedbCategory = response.data.selectedCategory;
          this.statement.nedbConsultationDate = response.data.consultation_date;
          this.statement.nedbConclusion = response.data.conclusion;
          this.statement.nedbRecommendation = response.data.recommendation;
          this.statement.nedbConclusionNumber = response.data.conclusion_list_number;
          this.statement.needDiseaseDocument = response.data.needDiseaseDocument;
          this.showNedbSpecialTypes = response.data.showNedbSpecialTypes ?? false;
          this.nedbSpecialTypesRequired = response.data.specialTypeRequired ?? false;
        } else {
          this.showNedbSpecialTypes = false;
          this.nedbSpecialTypesRequired = false;
        }
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
