import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import GroupsTable from './GroupsTable';

export default {
  name: 'VVoucherApplicationForm',
  components: { GroupsTable },
  props: {
    action: {
      type: String,
      required: true,
    },
    actionDraft: {
      type: String,
      default: null,
    },
    urlBack: {
      type: String,
      required: true,
    },
    urlRefs: {
      type: String,
      required: true,
    },
    preloadFiles: {
      type: Object,
      required: false,
    },
    fileTemplates: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {
        organization_id: null,
        name: null,
        bin: null,
        throughput: null,
        group_count: null,
        group_details: [],

        date: currentDayHelper.date,
      },
      files: {
        application_form: [],
        surveillance_info: [],
        medical_agreement: [],
        staffing_table: [],
        staff_docs: [],
        rental_agreement: [],
      },
    };
  },

  async mounted() {
    await this.$trans.add(['label', 'button', 'voucher', 'warning_text']);
    await requestGetDataHelper(this, {
      url: this.urlRefs,
      variableName: 'form',
    });
    if (this.preloadFiles && !_.isEmpty(this.preloadFiles)) {
      _.forEach(this.preloadFiles, (item, key) => {
        if (this.files[key]) {
          this.$set(this.files, key, item ?? []);
        }
      });
    }
  },

  methods: {
    fileRequired(key) {
      return [
        'application_form',
        'surveillance_info',
        'medical_agreement',
        'staffing_table',
        'staff_docs',
      ].includes(key);
    },
    currencyFormatter(number, digits = 2) {
      let formattedNumber = number;
      if (typeof number !== 'number') {
        formattedNumber = Number(number);
      }

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(formattedNumber)) {
        formattedNumber = 0;
      }

      return new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      }).format(formattedNumber);
    },
    async requestAction(isDraft = true) {
      this.$globalLoading.show();
      const url = isDraft ? this.actionDraft : this.action;
      const res = await requestWrapper.call(this, {
        method: 'post',
        url,
        data: { ...this.form, files: this.files },
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
