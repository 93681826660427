export default class AttendanceTimeSheetApiProvider {
  constructor(
    {
      TimeSheetRepository,
      TimeSheetMarkRepository,
      TimeSheetMassMarkRepository,
      TimeSheetAttendanceMarkRepository,
    }, trans,
  ) {
    this.timeSheetRepository = TimeSheetRepository;
    this.timeSheetMarkRepository = TimeSheetMarkRepository;
    this.timeSheetMassMarkRepository = TimeSheetMassMarkRepository;
    this.timeSheetAttendanceMarkRepository = TimeSheetAttendanceMarkRepository;
    this.trans = trans;
  }

  provideFetch() {
    const { timeSheetRepository, trans, responseErrorHandler } = this;
    // eslint-disable-next-line func-names
    return async function (groupId, date = null, isAlt) {
      try {
        const response = await timeSheetRepository.fetchData(groupId, date, isAlt);
        const { data: { status, data } } = response;
        if (status === 'ok') {
          return data;
        }
      } catch (error) {
        responseErrorHandler(this, error);
      }
      return false;
    };
  }

  // eslint-disable-next-line class-methods-use-this
  responseErrorHandler(vm, error) {
    if (error?.code === 'ERR_NETWORK') {
      vm.$notify({
        type: 'error',
        text: vm.trans('notice.internet_connection_error_try_again'),
      });
      return;
    }
    if (error.response.status === 403) {
      if (error.response.data.message) {
        vm.$notify({
          type: 'error',
          text: error.response.data.message,
        });
        if (error.response.data.redirect) {
          window.location.href = error.response.data.redirect;
        }
      }
    } else if (error.response.status === 404 || error.response.status === 422) {
      if (error.response.data.message) {
        vm.$notify({
          type: 'error',
          text: error.response.data.message,
        });
      }
    } else {
      vm.$notify({
        type: 'error',
        text: vm.trans('notice.error_on_server'),
      });
    }
  }

  provideAttendanceMark() {
    const { timeSheetAttendanceMarkRepository, trans, responseErrorHandler } = this;
    // eslint-disable-next-line func-names
    return async function (contingentId, status, date) {
      try {
        const response = await timeSheetAttendanceMarkRepository.attendanceMark(contingentId, status, date);
        const {
          data: {
            status: responseStatus, data, attendanceId, message,
          },
        } = response;
        if (['ok', 'validation-fail', 'validation_fail'].includes(responseStatus)) {
          return {
            data, responseStatus, attendanceId, message,
          };
        }
      } catch (error) {
        responseErrorHandler(this, error);
      }
      return false;
    };
  }

  provideMark() {
    const { timeSheetMarkRepository, trans, responseErrorHandler } = this;
    // eslint-disable-next-line func-names
    return async function (reason, id) {
      try {
        const response = await timeSheetMarkRepository.mark(reason, id);
        const { data: { status: responseStatus, data, message: responseMessage } } = response;
        if (responseStatus === 'validation-fail' && responseMessage) {
          this.$notify({
            type: 'error',
            text: responseMessage,
          });
        }
        if (responseStatus === 'ok' || responseStatus === 'validation-fail') {
          return { data, responseStatus };
        }
      } catch (error) {
        responseErrorHandler(this, error);
      }
      return false;
    };
  }

  provideMassMark() {
    const { timeSheetMassMarkRepository, trans, responseErrorHandler } = this;
    // eslint-disable-next-line func-names
    return async function (reason, ids) {
      try {
        const response = await timeSheetMassMarkRepository.mark(reason, ids);
        const { data: { status: responseStatus, data, message: responseMessage } } = response;
        if (responseStatus === 'validation-fail' && responseMessage) {
          this.$notify({
            type: 'error',
            text: responseMessage,
          });
        }
        if (responseStatus === 'ok' || responseStatus === 'validation-fail') {
          return { data, responseStatus };
        }
      } catch (error) {
        responseErrorHandler(this, error);
      }
      return false;
    };
  }
}
