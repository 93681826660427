// noinspection NpmUsedModulesInstalled
import {
  FUNDING_KINDERGARTEN_GROUPS_TRANSLATIONS,
} from '@vjs/balabaqsha/consts';

// noinspection NpmUsedModulesInstalled
import {
  QTable,
  QTr,
  QTd,
  QTh,
  QBtn,
  QSelect,
  QTooltip,
  QIcon,
} from '@quasar/components';

import _ from 'lodash';
import { requestWrapper } from '@vjs/helpers';
import PriceFormat from '../../Mixins/PriceFormat';
import Localization from '../../Mixins/QuasarLocale';
import KindergartenGroupListProviders
from '../Mixins/KindergartenGroupListProviders';
import DateYearMonthPicker from '../../Mixins/DateYearMonthPicker';

export default {
  name: 'VVoucherFundingKindergartenGroupContingentList',
  components: {
    QTable,
    QTr,
    QTd,
    QTh,
    QBtn,
    QSelect,
    QTooltip,
    QIcon,
  },
  mixins: [
    Localization, PriceFormat,
    DateYearMonthPicker, KindergartenGroupListProviders,
  ],
  props: {
    apiConfig: {
      type: Object,
      default: () => {
      },
    },
    dataConfig: {
      type: Object,
      default: () => ({
        date: null,
        groupName: null,
        group_id: null,
        kindergarten_id: null,
        kindergartenName: null,
      }),
    },
  },
  data() {
    // eslint-disable-next-line camelcase
    const { core_project } = window;
    const locale = core_project.locale || 'kz';
    // eslint-disable-next-line camelcase
    const {
      dataConfig: {
        groupName, group_id, date, kindergarten_id, kindergartenName, showActions,
      },
    } = this;

    return {
      locale,
      kindergarten_id,
      groupName,
      kindergartenName,
      showActions,
      groupId: group_id,
      childs: [],
      childsLoad: false,
      totalPlan: 0,
      totalFact: 0,
      planLock: false,
      currentDate: date,
    };
  },
  computed: {
    childsColumns() {
      const { $trans: { get: trans } } = this;
      const cols = [
        {
          name: 'fullname',
          field: 'fullname',
          label: trans('fields.fullname'),
        },
        {
          name: 'iin',
          field: 'iin',
          label: trans('fields.iin'),
        },
        {
          name: 'date',
          field: 'date',
          label: trans('fields.date'),
        },
        {
          name: 'financing_type',
          field: 'financing_type',
          label: trans('label.financing_type'),
        },
        {
          name: 'bank_account',
          field: 'bank_account',
          label: trans('fields.bank_account'),
        },
      ];

      if (this.showActions) {
        cols.push({
          name: 'actions',
          field: 'actions',
          label: trans('label.actions'),
          align: 'center',
        });
      }

      return cols;
    },
  },
  beforeCreate() {
    this.$trans.add(FUNDING_KINDERGARTEN_GROUPS_TRANSLATIONS);
  },
  async beforeMount() {
    this.initLocale();
    this.setDefaultDate();
    await this.loadContingent();
  },
  methods: {
    async loadContingent() {
      const { groupId, selectedDate, kindergarten_id } = this;
      this.childsLoad = true;

      const res = await requestWrapper.call(this, {
        method: 'get',
        url: `${this.apiConfig.child.apiUrl}/${groupId}`,
        params: { groupId, selectedDate, kindergarten_id },
      }, true);
      if (!res.error) {
        if (res.status !== 'ok') {
          this.$notify({
            type: 'warn',
            text: this.trans('notice.not_found_entity.kindergarten'),
          });
        } else {
          if (res.childs && Array.isArray(res.childs) && res.childs.length) {
            this.childs = res.childs;
          }

          // noinspection JSUnresolvedVariable
          if (res.total && res.total.plan) {
            this.totalPlan = res.total.plan;
          } else {
            this.totalPlan = 0;
          }
          // noinspection JSUnresolvedVariable
          if (res.total && res.total.fact) {
            this.totalFact = res.total.fact;
          }
          if (res.dates) {
            this.availableDates = res.dates;
          }
          if (res.date) {
            this.currentDate = res.date;
          }
          // noinspection JSUnresolvedVariable
          if (res.confirm && typeof res.confirm.plan !== 'undefined') {
            this.planLock = res.confirm.plan;
          }
        }
      } else if (res.error) {
        if (res.errorType === 404) {
          this.$notify({
            type: 'warn',
            text: this.trans('notice.not_found_entity.group'),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.trans('notice.error_on_server'),
          });
        }
      }
      this.childsLoad = false;
    },
    async onChangeYear(val) {
      this.currentYear = val;
      this.currentMonth = _.first(this.availableDates[val]);
      await this.loadContingent();
    },
    async onChangeMonth(val) {
      this.currentMonth = val;
      await this.loadContingent();
    },
  },
};
